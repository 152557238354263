export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/opt/build/repo/src/pages/index.vue'),
    /* no children */
    meta: {
      "auth": "mixed"
    }
  },
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/opt/build/repo/src/pages/[...path].vue'),
    /* no children */
  },
  {
    path: '/admin',
    /* internal name: '/admin' */
    /* no component */
    children: [
      {
        path: 'Observability',
        /* internal name: '/admin/Observability' */
        /* no component */
        children: [
          {
            path: ':chatrequestid',
            name: '/admin/Observability/[chatrequestid]',
            component: () => import('/opt/build/repo/src/pages/admin/Observability/[chatrequestid].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/child',
    /* internal name: '/child' */
    /* no component */
    children: [
      {
        path: '',
        name: '/child/',
        component: () => import('/opt/build/repo/src/pages/child/index.vue'),
        /* no children */
        meta: {
          "auth": "required"
        }
      },
      {
        path: 'explore',
        name: '/child/explore',
        component: () => import('/opt/build/repo/src/pages/child/explore.vue'),
        /* no children */
        meta: {
          "auth": "required"
        }
      },
      {
        path: 'favorites',
        name: '/child/favorites',
        component: () => import('/opt/build/repo/src/pages/child/favorites.vue'),
        /* no children */
        meta: {
          "auth": "required"
        }
      },
      {
        path: 'games',
        name: '/child/games',
        component: () => import('/opt/build/repo/src/pages/child/games.vue'),
        /* no children */
        meta: {
          "auth": "required"
        }
      },
      {
        path: 'images',
        name: '/child/images',
        component: () => import('/opt/build/repo/src/pages/child/images.vue'),
        /* no children */
        meta: {
          "auth": "required"
        }
      },
      {
        path: 'recents',
        name: '/child/recents',
        component: () => import('/opt/build/repo/src/pages/child/recents.vue'),
        /* no children */
        meta: {
          "auth": "required"
        }
      },
      {
        path: 'videos',
        name: '/child/videos',
        component: () => import('/opt/build/repo/src/pages/child/videos.vue'),
        /* no children */
        meta: {
          "auth": "required"
        }
      },
      {
        path: 'websites',
        name: '/child/websites',
        component: () => import('/opt/build/repo/src/pages/child/websites.vue'),
        /* no children */
        meta: {
          "auth": "required"
        }
      }
    ],
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/opt/build/repo/src/pages/dashboard.vue'),
    /* no children */
    meta: {
      "auth": "required"
    }
  },
  {
    path: '/family',
    /* internal name: '/family' */
    /* no component */
    children: [
      {
        path: '',
        name: '/family/',
        component: () => import('/opt/build/repo/src/pages/family/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/family/[id]',
        component: () => import('/opt/build/repo/src/pages/family/[id].vue'),
        /* no children */
      },
      {
        path: 'install',
        name: '/family/install',
        component: () => import('/opt/build/repo/src/pages/family/install.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/flow',
    /* internal name: '/flow' */
    /* no component */
    children: [
      {
        path: 'child',
        name: '/flow/child',
        component: () => import('/opt/build/repo/src/pages/flow/child.vue'),
        /* no children */
        meta: {
          "auth": "required",
          "isFirstUseFlow": true
        }
      },
      {
        path: 'guardian',
        name: '/flow/guardian',
        component: () => import('/opt/build/repo/src/pages/flow/guardian.vue'),
        /* no children */
        meta: {
          "auth": "required",
          "isFirstUseFlow": true
        }
      },
      {
        path: 'phone',
        name: '/flow/phone',
        component: () => import('/opt/build/repo/src/pages/flow/phone.vue'),
        /* no children */
        meta: {
          "auth": "required",
          "isFirstUseFlow": true
        }
      },
      {
        path: 'privacy',
        name: '/flow/privacy',
        component: () => import('/opt/build/repo/src/pages/flow/privacy.vue'),
        /* no children */
        meta: {
          "auth": "required",
          "isFirstUseFlow": true
        }
      },
      {
        path: 'signin',
        name: '/flow/signin',
        component: () => import('/opt/build/repo/src/pages/flow/signin.vue'),
        /* no children */
        meta: {
          "auth": "disallowed"
        }
      },
      {
        path: 'signup',
        name: '/flow/signup',
        component: () => import('/opt/build/repo/src/pages/flow/signup.vue'),
        /* no children */
        meta: {
          "auth": "disallowed"
        }
      },
      {
        path: 'terms',
        name: '/flow/terms',
        component: () => import('/opt/build/repo/src/pages/flow/terms.vue'),
        /* no children */
        meta: {
          "auth": "required",
          "isFirstUseFlow": true
        }
      },
      {
        path: 'who',
        name: '/flow/who',
        component: () => import('/opt/build/repo/src/pages/flow/who.vue'),
        /* no children */
        meta: {
          "auth": "required"
        }
      }
    ],
  },
  {
    path: '/for',
    /* internal name: '/for' */
    /* no component */
    children: [
      {
        path: ':affinitygroup',
        name: '/for/[affinitygroup]',
        component: () => import('/opt/build/repo/src/pages/for/[affinitygroup].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/guardian',
    name: '/guardian',
    component: () => import('/opt/build/repo/src/pages/guardian.vue'),
    /* no children */
  },
  {
    path: '/ipad',
    name: '/ipad',
    component: () => import('/opt/build/repo/src/pages/ipad.vue'),
    /* no children */
    meta: {
      "auth": "mixed"
    }
  },
  {
    path: '/ipadLOTL',
    name: '/ipadLOTL',
    component: () => import('/opt/build/repo/src/pages/ipadLOTL.vue'),
    /* no children */
    meta: {
      "auth": "mixed"
    }
  },
  {
    path: '/logins',
    /* internal name: '/logins' */
    /* no component */
    children: [
      {
        path: 'review-:id',
        name: '/logins/review-[id]',
        component: () => import('/opt/build/repo/src/pages/logins/review-[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/newAdult-:id',
    name: '/newAdult-[id]',
    component: () => import('/opt/build/repo/src/pages/newAdult-[id].vue'),
    /* no children */
  },
  {
    path: '/signout',
    name: '/signout',
    component: () => import('/opt/build/repo/src/pages/signout.vue'),
    /* no children */
  },
  {
    path: '/sso-callback',
    name: '/sso-callback',
    component: () => import('/opt/build/repo/src/pages/sso-callback.vue'),
    /* no children */
  },
  {
    path: '/u',
    /* internal name: '/u' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/u/[id]',
        component: () => import('/opt/build/repo/src/pages/u/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/use-family',
    name: '/use-family',
    component: () => import('/opt/build/repo/src/pages/use-family.vue'),
    /* no children */
  },
  {
    path: '/users',
    name: '/users',
    component: () => import('/opt/build/repo/src/pages/users.vue'),
    children: [
      {
        path: '',
        name: '/users/',
        component: () => import('/opt/build/repo/src/pages/users/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/users/[id]',
        component: () => import('/opt/build/repo/src/pages/users/[id].vue'),
        /* no children */
      },
      {
        path: 'admin',
        name: '/users/admin',
        component: () => import('/opt/build/repo/src/pages/users/admin.vue'),
        children: [
          {
            path: '',
            name: '/users/admin/',
            component: () => import('/opt/build/repo/src/pages/users/admin/index.vue'),
            /* no children */
          },
          {
            path: 'affinity-groups',
            name: '/users/admin/affinity-groups',
            component: () => import('/opt/build/repo/src/pages/users/admin/affinity-groups.vue'),
            /* no children */
          },
          {
            path: 'all-users',
            name: '/users/admin/all-users',
            component: () => import('/opt/build/repo/src/pages/users/admin/all-users.vue'),
            /* no children */
          },
          {
            path: 'classification-tester',
            name: '/users/admin/classification-tester',
            component: () => import('/opt/build/repo/src/pages/users/admin/classification-tester.vue'),
            /* no children */
          },
          {
            path: 'feature-flags',
            name: '/users/admin/feature-flags',
            component: () => import('/opt/build/repo/src/pages/users/admin/feature-flags.vue'),
            /* no children */
          },
          {
            path: 'indexed-videos',
            name: '/users/admin/indexed-videos',
            component: () => import('/opt/build/repo/src/pages/users/admin/indexed-videos.vue'),
            /* no children */
          },
          {
            path: 'mixpanel-tagger',
            name: '/users/admin/mixpanel-tagger',
            component: () => import('/opt/build/repo/src/pages/users/admin/mixpanel-tagger.vue'),
            /* no children */
          },
          {
            path: 'phone-numbers',
            name: '/users/admin/phone-numbers',
            component: () => import('/opt/build/repo/src/pages/users/admin/phone-numbers.vue'),
            /* no children */
          },
          {
            path: 'prompt-editor-test',
            name: '/users/admin/prompt-editor-test',
            component: () => import('/opt/build/repo/src/pages/users/admin/prompt-editor-test.vue'),
            /* no children */
          },
          {
            path: 'prompt-ingredients',
            name: '/users/admin/prompt-ingredients',
            component: () => import('/opt/build/repo/src/pages/users/admin/prompt-ingredients.vue'),
            /* no children */
          },
          {
            path: 'prompt-tests',
            name: '/users/admin/prompt-tests',
            component: () => import('/opt/build/repo/src/pages/users/admin/prompt-tests.vue'),
            /* no children */
          },
          {
            path: 'prompts',
            name: '/users/admin/prompts',
            component: () => import('/opt/build/repo/src/pages/users/admin/prompts.vue'),
            /* no children */
          },
          {
            path: 'scheduled-activity-summaries',
            name: '/users/admin/scheduled-activity-summaries',
            component: () => import('/opt/build/repo/src/pages/users/admin/scheduled-activity-summaries.vue'),
            /* no children */
          },
          {
            path: 'shortlinks',
            name: '/users/admin/shortlinks',
            component: () => import('/opt/build/repo/src/pages/users/admin/shortlinks.vue'),
            /* no children */
          },
          {
            path: 'test-ios-rating',
            name: '/users/admin/test-ios-rating',
            component: () => import('/opt/build/repo/src/pages/users/admin/test-ios-rating.vue'),
            /* no children */
          },
          {
            path: 'top-families',
            name: '/users/admin/top-families',
            component: () => import('/opt/build/repo/src/pages/users/admin/top-families.vue'),
            /* no children */
          },
          {
            path: 'user-roles',
            name: '/users/admin/user-roles',
            component: () => import('/opt/build/repo/src/pages/users/admin/user-roles.vue'),
            /* no children */
          },
          {
            path: 'welcome-greetings',
            name: '/users/admin/welcome-greetings',
            component: () => import('/opt/build/repo/src/pages/users/admin/welcome-greetings.vue'),
            /* no children */
          },
          {
            path: 'wonder-chats',
            name: '/users/admin/wonder-chats',
            component: () => import('/opt/build/repo/src/pages/users/admin/wonder-chats.vue'),
            /* no children */
          }
        ],
        meta: {
          "auth": "required"
        }
      },
      {
        path: 'gifts',
        /* internal name: '/users/gifts' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/users/gifts/[id]',
            component: () => import('/opt/build/repo/src/pages/users/gifts/[id].vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "auth": "required"
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

